import React from "react";
import Layout from "../../components/layout";
import Hero from "../../components/sections/hero";
import DestraSinistra from "../../components/sections/destra-sinistra";
import Error from "../../components/error";
import { isEmpty } from 'lodash';
import SEO from "../../components/seo";

import "bootstrap/js/dist/carousel.js";


const FrontPage = ( props ) => {

	const {
		      pageContext: {
			      page: { frontPageMeta: { hero, compDxSx } }
		      }
	      } = props;

				// console.log(props);

	return (
		<Layout>
			{
				! isEmpty( props.pageContext ) ? (
					<>

						<SEO title="Luxury Trade Italia" seo={props?.pageContext?.page?.seo} uri={'/'}/>
						<Hero className="mb-9vw" data={ hero }/>
						<DestraSinistra data={ compDxSx }/>

					</>
				) : (
					<Error message="Something Went Wrong"/>
				)
			}
		</Layout>
	)
};
export default FrontPage;
